let CalendarDirective = function(
    $scope, 
    $element, 
    $timeout,
    $q,
    AccommodationsService,
    AccommodationTypesService,
    DateService,
    FormBuilderService,
) {
    $scope.selectedDate = moment();
    $scope.dateFormat = 'dd-MM-yyyy';
    $scope.firstMonthDay = null;
    $scope.lastMonthDay = null;
    $scope.disabledWeekDay = 7;

    $scope.disablePrevBtn = true;
    $scope.monthViewLoading = true;

    $scope.dateSelected = {
        month: $scope.selectedMonth
    };

    $scope.syncSelection = (date) => {
        $scope.selectedYear = date.year();
        $scope.selectedMonth = date.month(); // starting from 0
        $scope.dateSelected.month = $scope.selectedMonth;

        $scope.refreshCalendar();
    }

    $scope.showPopup = false;
    
    $scope.weekdayList = moment.weekdays(true);

    $scope.monthList = [];
    moment.months().map((monthName, index) => {
        $scope.monthList.push({
            id: index,
            name: monthName, 
        });
    });

    $scope.mapDate = (momentDate) => {
        return {
            day: momentDate.date(),
            month: momentDate.month(),
            year: momentDate.year(),
            momentDate: momentDate,
            weekDay: momentDate.isoWeekday(),
            isPast: !moment().isSame(momentDate, 'day') && moment().isAfter(momentDate)
        };
    }

    $scope.getMonthDays = (year, month) => {
        let monthLiteral = month + 1;
        let daysInMonth = moment(year + "-" + monthLiteral, "YYYY-MM").daysInMonth();
        let days = [];

        for (let i = 1; i <= daysInMonth; i++) {
            let date = moment([year, monthLiteral, i].join('-'), "YYYY-MM-DD");
            
            days.push($scope.mapDate(date));
        }

        return days;
    }

    $scope.getDisplayedDays = () => {
        let currentMonthDays = $scope.getMonthDays(
            $scope.selectedYear, $scope.selectedMonth
        );

        $scope.firstMonthDay = currentMonthDays[0];
        $scope.lastMonthDay = currentMonthDays[currentMonthDays.length - 1];

        if ($scope.firstMonthDay.weekDay > 1) {
            // Show some days from previous month
            
            let day = angular.copy($scope.firstMonthDay.momentDate);
            while(day.isoWeekday() > 1) {
                day.subtract(1, "days");
                currentMonthDays.unshift($scope.mapDate(day));
            }
        }

        if ($scope.lastMonthDay.weekDay < 7) {
            // Show some days from next month
            
            let day = angular.copy($scope.lastMonthDay.momentDate);
            while(day.isoWeekday() < 7) {
                day.add(1, "days");
                currentMonthDays.push($scope.mapDate(day));
            }
        }

        return currentMonthDays;
    }

    $scope.setDatePickerDateLimits = (accommodation) => {
        let firstDateAvailable = angular.copy($scope.accommodationDate.momentDate);
        let lastDateAvailable = angular.copy(moment(accommodation.endDateAvailable));

        let lastDateDiff = 1;

        // Temporary change
        if (firstDateAvailable.year() == 2021 && accommodation.accommodation_type_id != 2) {
            if (firstDateAvailable.month() >= 4 && firstDateAvailable.month() <= 8) {
                // Booking duration should be >= 2 days

                if (firstDateAvailable.isoWeekday() == 6 || firstDateAvailable.isoWeekday() == 7) {
                    // weekend
                    lastDateDiff = 2;
                } else {
                    // weekday, july / august
                    if (firstDateAvailable.month() == 6 || firstDateAvailable.month() == 7) {
                        lastDateDiff = 2;
                    }
                }
            }
        }

        let afterFirstDateAvailable = angular.copy(firstDateAvailable).add(lastDateDiff, 'days');
        let beforeLastDateAvailable = angular.copy(lastDateAvailable).subtract(1, 'days');

        $scope.ctrl.accommodationForm.values.start_date = firstDateAvailable.format("DD-MM-YYYY");
        $scope.ctrl.accommodationForm.values.end_date = afterFirstDateAvailable.format("DD-MM-YYYY");
        
        $scope.ctrl.minStartDate = firstDateAvailable.toDate().toDateString();
        $scope.ctrl.maxStartDate = beforeLastDateAvailable.toDate().toDateString();
        $scope.ctrl.minEndDate = afterFirstDateAvailable.toDate().toDateString();
        $scope.ctrl.maxEndDate = lastDateAvailable.toDate().toDateString();
    }

    $scope.showAccommodation = ($event, accommodation, accommodationDate) => {
        $event.originalEvent.stopPropagation();
        $event.originalEvent.preventDefault();

        $scope.selectedAccommodation = accommodation;
        $scope.accommodationDate = accommodationDate;
        $scope.setDatePickerDateLimits(accommodation);
        $scope.ctrl.accommodationForm.configureAccommodation($scope.selectedAccommodation);
        $scope.ctrl.nrNights = 1;
    }

    $scope.isNextMonth = (date) => {
        let dateYear = date.year();
        let dateMonth = date.month();
        let selectedYear = $scope.selectedDate.year();
        let selectedMonth = $scope.selectedDate.month();

        return selectedYear == dateYear && selectedMonth < dateMonth;
    }

    $scope.isCurrentMonth = (date) => {
        let dateYear = date.year();
        let dateMonth = date.month();
        let currentYear = moment().year();
        let currentMonth = moment().month();

        return dateYear == currentYear && dateMonth == currentMonth;
    }

    $scope.increaseMonth = ($event) => {
        $scope.monthViewLoading = true;

        $scope.selectedDate.add(1, "month");
        $scope.syncSelection($scope.selectedDate);
        $scope.disablePrevBtn = false;
    }

    $scope.decreaseMonth = ($event) => {
        if ($scope.isCurrentMonth($scope.selectedDate)) {
            $scope.disablePrevBtn = true;
            return;
        }

        $scope.monthViewLoading = true;

        $scope.selectedDate.subtract(1, "month");
        $scope.syncSelection($scope.selectedDate);
        $scope.disablePrevBtn = false;

        if ($scope.isCurrentMonth($scope.selectedDate)) {
            $scope.disablePrevBtn = true;
        }
    }

    $scope.refreshCalendar = () => {
        $scope.dayList = $scope.getDisplayedDays();

        $scope.loadAccommodations();
    }

    $scope.closePopup = ($event, forceClose = false) => {
        $event.stopPropagation();
        $event.preventDefault();

        if (!$event.target.closest('.popup') || forceClose) {
            $timeout(() => {
                $scope.selectedAccommodation = null;
            }, 50);
        }
    }

    $scope.mapAccommodationDays = (accommodation_days) => {
        $scope.accommodationsArr = [];
        $scope.accommodations.forEach(accommodation => {
            $scope.accommodationsArr[accommodation.id] = accommodation;
        });

        accommodation_days.forEach((accommodation_day, indexDay) => {
            Object.values(accommodation_day.accommodations).forEach((accommodationId, indexAccomodation) => {
                let date = accommodation_day.date;
                let day  = $scope.dayList.filter(day => {
                    return !day.isPast && day.momentDate.isSame(moment(date), 'day');
                })[0];
                let accommodation = angular.copy($scope.accommodationsArr[accommodationId]);

                if (day) {
                    if (typeof day.accommodations == 'undefined') {
                        day.accommodations = [];
                    }

                    let pos = day.accommodations.map(_accommodation => { 
                        return _accommodation.id; 
                    }).indexOf(accommodationId);

                    if (pos == -1) {
                        accommodation.endDateAvailable = moment(date).add(1, 'days').format("YYYY-MM-DD");

                        let nextAccommodationDayIndex = indexDay + 1;
                        let nextDay = accommodation_days[nextAccommodationDayIndex];

                        while (typeof nextDay != 'undefined' && (
                            Object.values(nextDay.accommodations).indexOf(accommodationId) != -1)
                        ) {    
                            nextDay = accommodation_days[++nextAccommodationDayIndex];
                            if (typeof nextDay != 'undefined') {
                                accommodation.endDateAvailable = angular.copy(nextDay.date);
                            }
                        }

                        let ignoreAccommodation = false;

                        // Temporary change
                        let endDateMoment = moment(accommodation.endDateAvailable);
                        if (endDateMoment.diff(day.momentDate, 'days') == 1) {
                            if (day.momentDate.year() == 2021 && accommodation.accommodation_type_id != 2) {
                                if (day.momentDate.month() >= 4 && day.momentDate.month() <= 8) {
                                    // Booking duration should be >= 2 days
    
                                    if (day.momentDate.isoWeekday() == 6 || day.momentDate.isoWeekday() == 7) {
                                        // weekend
                                        ignoreAccommodation = true;
                                    } else {
                                        // weekday, july / august
                                        if (day.momentDate.month() == 6 || day.momentDate.month() == 7) {
                                            ignoreAccommodation = true;
                                        }
                                    }
                                }
                            }
                        }

                        if (!ignoreAccommodation) {
                            day.accommodations.push(accommodation);
                        }
                    }
                }
            });
        });
    }

    $scope.loadAccommodations = () => {
        let startDate = $scope.firstMonthDay.momentDate.isBefore(moment(), 'day') ? 
            moment() : $scope.firstMonthDay.momentDate;
        let endDate = angular.copy(startDate);
        let promises = [];

        for (let i = 0; i < 4; i++) {
            promises.push(AccommodationsService.indexPost({
                start_date: endDate.format('DD-MM-YYYY') || null,
                end_date: endDate.add(15, "days").format('DD-MM-YYYY') || null,
            }).then((res) => {
                return res.data;
            }));
        }
    
        AccommodationsService.indexAll().then(res => {
            $scope.accommodations = res.data.data;

            let accommodation_days = [];
            $q.all(promises).then(function(result){
                for (var i = 0; i < result.length; i++){
                    accommodation_days = accommodation_days.concat(result[i]);
                }

                $scope.mapAccommodationDays(accommodation_days);
                $scope.monthViewLoading = false;
            });
        });
    }

    $scope.bookAccommodation = () => {
        if ($scope.ctrl.accommodationForm.values.start_date && $scope.ctrl.accommodationForm.values.end_date) {
            $scope.ctrl.accommodationForm.calcAccommodationPrice().then((res) => {
                $scope.ctrl.accommodationForm.submitAccommodation();
            });
        }
    }

    $scope.isActiveDay = (date) => {
        if (typeof date.accommodations == 'undefined') {
            return false;
        }

        let accommodationsOfSelectedType = date.accommodations.filter(accommodation => {
            return accommodation.accommodation_type_id == $scope.selectedAccommodationType;
        });

        return !date.isPast && 
            accommodationsOfSelectedType.length && 
            moment(date).isoWeekday() != $scope.disabledWeekDay && 
            moment(date).month() == $scope.selectedMonth;
    }

    $scope.init = () => {
        if ($scope.dayList && $scope.dayList.length) {
            return;
        }

        $scope.syncSelection($scope.selectedDate);

        AccommodationTypesService.index().then((res) => {
            $scope.accommodationTypes = res.data.data;

            $scope.selectedAccommodationType = $scope.accommodationTypes[0].id;
        });
    }

    $scope.selectAccommodationType = (accommodationType) => {
        $scope.selectedAccommodationType = accommodationType.id;
    }

    $scope.init();
};

module.exports = () => {
    return {
        restrict: 'E',
        scope: {
            'ctrl': '=',
            'scroller': '=',
            'invoice': '=',
            'invoiceResource': '='
        },
        controller: [
            '$scope',
            '$element',
            '$timeout',
            '$q',
            'AccommodationsService',
            'AccommodationTypesService',
            'DateService',
            'FormBuilderService',
            CalendarDirective
        ],
        templateUrl: './assets/tpl/directives/calendar-directive.html'
    };
};