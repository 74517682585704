module.exports = ['ModalRouteProvider', function(ModalRouteProvider) {
    ModalRouteProvider.modal('cart', {
        component: 'modalCartComponent'
    });

    ModalRouteProvider.modal('cart_quantity', {
        component: 'modalCartQuantityComponent'
    });

    ModalRouteProvider.modal('cart_gift_card_editor', {
        component: 'modalCartGiftCardEditorComponent'
    });

    ModalRouteProvider.modal('accommodation_select', {
        component: 'modalAccommodationPopupComponent'
    });
}];