let BaseController = function(
    $scope
) {
    let $ctrl = {};

    $ctrl.dateFormat = 'dd-MM-yyyy'

    $ctrl.minStartDate = moment().toDate().toDateString();
    $ctrl.minEndDate = moment().add(1, 'days').toDate().toDateString();
    $ctrl.maxDate = moment().add(3, 'months').toDate().toDateString();
    $ctrl.startDate;
    $ctrl.endDate;

    $ctrl._dateParse = (date) => {
        return moment(date, $ctrl.dateFormat.toUpperCase());
    };

    $ctrl._dateFormat = (date) => {
        return date.format($ctrl.dateFormat.toUpperCase());
    };

    $scope.$ctrl = $ctrl;

    $scope.$watch('$ctrl', (value) => {
        let start_date = $ctrl._dateParse($ctrl.startDate);
        let end_date = $ctrl._dateParse($ctrl.endDate);

        if (start_date._isValid && !end_date._isValid) {
            return $ctrl.endDate = $ctrl._dateFormat(
                start_date.clone().add(1, 'days')
            );
        }

        if (start_date._isValid && end_date._isValid) {
            if (!end_date.isAfter(start_date)) {
                return $ctrl.endDate = $ctrl._dateFormat(
                    start_date.clone().add(1, 'days')
                );
            }
            
            $ctrl.minEndDate = start_date.clone().add(1, 'days').toDate().toDateString();
        }
    }, true);
};

module.exports = [
    '$scope',
    BaseController
];