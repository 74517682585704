let lazy = [];
let lazyBg = [];
let debug = false;

let setLazy = () => {
    lazy = document.querySelectorAll('img[data-src]');
    lazyBg = document.querySelectorAll('[data-background]');
    
    if (debug) {
        console.log('Found ' + lazy.length + ' lazy images');
        console.log('Found ' + lazyBg.length + ' lazy backgrounds');
    }
}

let lazyLoad = () => {
    for (var i = 0; i < lazy.length; i++) {
        if (isInViewport(lazy[i])) {
            if (lazy[i].getAttribute('data-src')) {
                lazy[i].src = lazy[i].getAttribute('data-src');
                lazy[i].removeAttribute('data-src');
            }
        }
    }

    for (var j = 0; j < lazyBg.length; j++) {
        if (isInViewport(lazyBg[j])) {
            let bg = lazyBg[j].getAttribute('data-background');
            
            if (bg) {
                lazyBg[j].style.backgroundImage = "url('" + bg + "')";
                lazyBg[j].removeAttribute('data-background');
            }
        }
    }

    cleanLazy();
}

let cleanLazy = () => {
    lazy = Array.prototype.filter.call(lazy, function(l) {
        return l.getAttribute('data-src');
    });

    lazyBg = Array.prototype.filter.call(lazyBg, function(l) {
        return l.getAttribute('data-background');
    });
}

let isInViewport = (el) => {
    var rect = el.getBoundingClientRect();
    let offset = 200;

    return (
        rect.bottom >= 0 &&
        rect.right >= 0 &&
        rect.top <= ((window.innerHeight || document.documentElement.clientHeight) + offset) &&
        rect.left <= ((window.innerWidth || document.documentElement.clientWidth) + offset)
    );
}

let registerListener = (event, func) => {
    if (window.addEventListener) {
        window.addEventListener(event, func)
    } else {
        window.attachEvent('on' + event, func)
    }
}

registerListener('load', setLazy);
registerListener('load', lazyLoad);
registerListener('scroll', lazyLoad);
registerListener('resize', lazyLoad);

module.exports = {
    update: () => {
        debug && console.log('Update!');
        setLazy();
    }
};