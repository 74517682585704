let ModalCartQuantityComponent = function(
    FormBuilderService
) {
    let $ctrl = this;

    $ctrl.add = () => $ctrl.quantity++;
    $ctrl.sub = () => $ctrl.quantity = Math.max($ctrl.quantity - 1, 1);

    $ctrl.submit = (checkout = false) => {
        $ctrl.modal.scope.submit(
            $ctrl.offer,
            $ctrl.quantity,
            checkout
        );
        $ctrl.close();
    };

    $ctrl.$onInit = () => {
        $ctrl.offer = $ctrl.modal.scope.offer;
        $ctrl.quantity = $ctrl.modal.scope.quantity;
    };

    $ctrl.$onDestroy = function() {};
};

module.exports = {
    bindings: {
        close: '=',
        modal: '='
    },
    controller: [
        'FormBuilderService',
        ModalCartQuantityComponent
    ],
    templateUrl: () => {
        return '/assets/tpl/modals/offers/modal-cart-quantity.html';
    }
};