let OffersComponent = function(
    $q,
    $filter,
    DateService,
    OfferService,
    ModalService,
    FormBuilderService,
    AccommodationsService,
    AccommodationTypesService,
    CustomOfferService,
) {
    let $ctrl = this;
    let $currency_format = $filter('currency_format');
    let $translate = $filter('translate');

    $ctrl.invoice;
    $ctrl.offers;
    $ctrl.activity_offers;
    $ctrl.accommodation_offers;
    $ctrl.state = 'list';
    $ctrl.customOfferExpirationDate = DateService._datePrettyFormat(
        moment().add(1, 'year')
    );

    $ctrl.disableBasket = true;
    $ctrl.accommodations = [];
    $ctrl.accommodationTypes = [];

    $ctrl.cart = {
        items: [],
        stats: {
            count_activities: 0,
            count_accommodations: 0,
        },
        updateStatitstics: function() {
            this.stats.count_activities = this.items.filter(
                item => item.offer.type == 'activity'
            ).length;

            this.stats.count_accommodations = this.items.filter(
                item => item.offer.type == 'accommodation'
            ).length;
        },
        addItem: function(offer, quantity = 1) {
            if (offer.in_cart) {
                return;
            }

            offer.in_cart = true;
            this.items.push({
                offer: offer,
                quantity: quantity,
            });
            this.syncInvoice();
            this.updateStatitstics();
        },
        removeItem: function(offer) {
            offer.in_cart = false;
            this.items = this.items.filter(_offer => _offer.offer != offer);
            this.syncInvoice();
            this.updateStatitstics();

            if (this.items.length == 0 && $ctrl.state != 'list') {
                $ctrl.goState('list');
            }
        },
        syncInvoice: function() {
            this.invoice = this.toInvoice();
        },
        toInvoice: function() {
            let invoice = {
                invoice_number: $translate('booking.invoice_order_overview'),
                items: [],
                state: 'pending',
            };

            let btw = {
                totals: {
                    price_inclusive: 0,
                    price_exclusive: 0,
                    btw: {},
                },
                add: function(size, price) {
                    if (!this.totals.btw[size]) {
                        this.totals.btw[size] = 0;
                    }

                    this.totals.price_inclusive += price.price_inclusive;
                    this.totals.price_exclusive += price.price_exclusive;
                    this.totals.btw[size] += price.btw;
                }
            };

            this.items.forEach(item => {
                let quantity_description = 'quantity_description';
                let offer = item.offer;

                let price = {
                    price_inclusive: offer.price.price_inclusive * item.quantity,
                    price_exclusive: offer.price.price_exclusive * item.quantity,
                    btw: offer.price.btw * item.quantity,
                };

                if (offer.type == 'accommodation') {
                    quantity_description = (item.quantity * offer.nights) + ' ' +
                        $translate('booking.nights');
                } else if (offer.type == 'fixed') {
                    quantity_description = item.quantity * offer.nights;
                } else if (offer.type == 'activity') {
                    quantity_description = item.quantity;
                }

                invoice.items.push({
                    name: offer.name,
                    //description: offer.description,
                    type: 'booking_' + offer.type,
                    price: price,
                    quantity: item.quantity,
                    quantity_description: quantity_description,
                    //description: '- voor ' + offer.people + ' persoon',
                    description: '- ' + $translate('booking.for_nr_persons', {
                        nr_persons: offer.people
                    }),
                });

                btw.add(offer.btw, price)
            });

            invoice.btw = btw;

            return invoice;
        },
        countOffers: function() {
            return this.items.reduce((sum, item) => {
                return sum + item.quantity;
            }, 0);
        }
    };

    $ctrl.giftCardEditor = () => {
        ModalService.open('cart_gift_card_editor', {
            accommodations: $ctrl.accommodations,
            accommodationTypes: $ctrl.accommodationTypes,
            submit: (offer) => {

                if (offer) {
                    $ctrl.cart.addItem(offer);
                    $ctrl.goState('invoice');
                }
            }
        });
    };

    $ctrl.addCartItem = function(offer, quantity = 1) {
        if (offer.in_cart) {
            return;
        }

        if (offer.type == 'activity') {
            return $ctrl.cart.addItem(offer, 1);
        }

        ModalService.open('cart_quantity', {
            offer: offer,
            quantity: quantity,
            submit: (offer, quantity, checkout = false) => {
                $ctrl.cart.addItem(offer, quantity);

                if (checkout) {
                    $ctrl.goState('invoice');
                }
            }
        });
    };

    $ctrl.goState = (state) => {
        switch (state) {
            case 'invoice':
                $ctrl.onStateInvoice();
                break;
        }

        $ctrl.state = state;
    };

    $ctrl.onStateInvoice = () => {
        $ctrl.cart.invoice = $ctrl.cart.toInvoice();
    };

    $ctrl.showCart = () => {
        ModalService.open('cart', {
            cart: $ctrl.cart,
            submit: () => $ctrl.goState('invoice'),
        });
    };

    $ctrl.initForm = () => {
        $ctrl.form = FormBuilderService.build({
            combined: $ctrl.disableBasket ? false : null
        }, function(form) {
            let values = Object.assign({
                locale: $ctrl.locale || 'nl',
                combined: null,
                offers: $ctrl.cart.items.map(item => ({
                    id: item.offer.id,
                    quantity: item.quantity,
                }))
            }, form.values);

            if ($ctrl.cart.countOffers() === 1) {
                values.combined = false;
            }

            return OfferService.checkout(values).then(res => {
                $ctrl.reserving = false;
                let url = res.data.data.url_checkout;
                let _form = $('<form action="' + url + '" method="post"></form>');
                $('body').append(_form);
                _form.submit();
            }, res => {
                $ctrl.form.errors = res.data.errors;
            });
        });
    };

    $ctrl.loadCustomOffer = () => {
        return $q((resolve, reject) => {
            CustomOfferService.index().then(res => {
                resolve($ctrl.customOffer = res.data.data[0]);
            }, reject);
        });
    }

    $ctrl.loadOffers = () => {
        return $q((resolve, reject) => {
            OfferService.index().then(res => {
                resolve($ctrl.offers = res.data.data.map(offer => {
                    offer.price_format = $currency_format(offer.price.price_inclusive);
                    offer.old_price_format = $currency_format(offer.old_price);

                    offer.description = $translate(
                        'offers.expires_at'
                    ) + ': ' + offer.expires_at_locale;

                    if (offer.name) {
                        offer.name_translated = offer.name;
                        return offer;
                    }

                    offer.name_translated = offer.type == 'accommodation' ?
                        $translate('offers.cart_accommodation_title', {
                            nr_nights: offer.nights,
                            accommodation_name: offer.accommodation.name,
                            nr_people: offer.people
                        }) :
                        $translate('offers.cart_activity_title', {
                            accommodation_name: offer.accommodation ? offer.accommodation.name : '',
                            nr_people: offer.people
                        });

                    return offer;
                }));
            }, reject)
        });
    };

    $ctrl.loadAccommodationTypes = () => {
        return $q((resolve, reject) => {
            AccommodationTypesService.index().then((res) => {
                resolve($ctrl.accommodationTypes = res.data.data);
            }, reject);
        });
    };

    $ctrl.loadAccommodations = () => {
        return $q((resolve, reject) => {
            AccommodationsService.indexAll().then((res) => {
                resolve($ctrl.accommodations = res.data.data.map(accommodation => {
                    accommodation.peopleOptions = [
                        ...Array(accommodation.people_max - (
                            accommodation.people_min - 1
                        )).keys()
                    ].map(item => item + accommodation.people_min)

                    accommodation.options.unshift({
                        id: 'null',
                        name: 'Geen',
                        price: 0,
                    });

                    return accommodation;
                }));
            }, reject);
        });
    }

    $ctrl.$onInit = () => {
        $q.all([
            $ctrl.loadCustomOffer(),
            $ctrl.loadOffers(),
            $ctrl.loadAccommodations(),
            $ctrl.loadAccommodationTypes(),
        ]).then(() => {
            $ctrl.accommodation_offers = $ctrl.offers.filter(
                item => item.type == 'accommodation'
            );

            $ctrl.activity_offers = $ctrl.offers.filter(
                item => item.type == 'activity'
            );

            $ctrl.fixed_offers = $ctrl.offers.filter(
                item => item.type == 'fixed'
            );

            $ctrl.initForm();
        });
    };
};

module.exports = {
    bindings: {
        locale: '@',
        page: '<',
        params: '<',
        onChange: '&',
    },
    controller: [
        '$q',
        '$filter',
        'DateService',
        'OfferService',
        'ModalService',
        'FormBuilderService',
        'AccommodationsService',
        'AccommodationTypesService',
        'CustomOfferService',
        OffersComponent
    ],
    templateUrl: '/assets/tpl/pages/offers.html'
};