let BookingsService = function(ApiRequest) {
    let uriPrefix = '/bookings';

    return new(function() {
        this.store = function(data) {
            return ApiRequest.post(uriPrefix, data);
        };

        this.validate = function(type, data) {
            return ApiRequest.post(uriPrefix + '/validate/' + type, data);
        };

        this.calcPrice = function(data) {
            return ApiRequest.post(uriPrefix + '/calc-price', data);
        };
    });
};

module.exports = [
    'ApiRequest',
    BookingsService
];