let DatePickerDirective = function(scope, element, attrs, ctrl) {
    $(element).datepicker({
        dateFormat: 'dd/mm/yy',
        onSelect: function(date) {
            ctrl.$setViewValue(date);
            ctrl.$render();
            scope.$apply();
        }
    });
}

module.exports = () => {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: DatePickerDirective 
    };
};