let BaseController = function(
    $rootScope,
    $scope,
    BookingsService,
    UrlService,
    appConfigs,
) {
    $rootScope.appConfigs = appConfigs;
    $scope.appConfigs = appConfigs;
    $scope.currentStep = 0;

    $scope.slickConfig = {
        infinite: false, 
        dots: false, 
        slidesToShow: 1, 
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        event: {
            onChange: function (event, slick, currentSlide, nextSlide) {
                if($scope.validateStep()){
                    $scope.slickCurrentIndex2 = currentSlide;
                }
            },
            init: function (event, slick) {
                slick.slickGoTo($scope.slickCurrentIndex2); // slide to correct index when init
            }
        },
        method: {}
    };

    BookingsService.apartmentList().then((res) => {
        if(res.data.list){
            $scope.apartmentList = res.data.list.hotel;
            $scope.campingList = res.data.list.camping;
        }
    });

    BookingsService.serviceList().then((res) => {
        if(res.data.list){
            $scope.serviceList = res.data.list;
        }
    });

    $scope.choose = function($event, place){
        var item = angular.element($event.currentTarget);

        $scope.selectedPlace = place;

        item.closest('.place-wrapper').addClass('active');

        $event.preventDefault();
        $event.stopPropagation();
    }

    $scope.deselect = function($event){
        var item = angular.element($event.currentTarget);

        $scope.selectedPlace = null;

        item.closest('.place-wrapper').removeClass('active');

        $event.preventDefault();
        $event.stopPropagation();
    }

    $scope.goPrevStep = function(){
        $scope.slickConfig.method.slickGoTo(--$scope.currentStep);
    };

    $scope.goNextStep = function(){
        if($scope.currentStep == 0){
            if($scope.selectedPlace){
                $scope.slickConfig.method.slickGoTo(++$scope.currentStep);
                $scope.errorMsg = null;
            }else{
                $scope.errorMsg = "Please select a place";
            }
            return;
        }

        if($scope.currentStep == 1){
            //Add some validation later
            $scope.slickConfig.method.slickGoTo(++$scope.currentStep);
            $scope.errorMsg = null;
            return;
        }

        if($scope.currentStep == 2){
            //Add some validation later
            $scope.slickConfig.method.slickGoTo(++$scope.currentStep);
            $scope.errorMsg = null;
            return;
        }
    };

    $scope.maxGuests = 6;
    $scope.months = [
        {value: '1', name: 'January'},
        {value: '2', name: 'February'},
        {value: '3', name: 'March'},
        {value: '4', name: 'April'},
        {value: '5', name: 'May'},
        {value: '6', name: 'June'},
        {value: '7', name: 'July'},
        {value: '8', name: 'August'},
        {value: '9', name: 'September'},
        {value: '10', name: 'October'},
        {value: '11', name: 'November'},
        {value: '12', name: 'December'},
    ];
    
    $scope.years = [];

    for(let i = 1900; i <= new Date().getFullYear()-16; ++i){
        $scope.years.push({
            value: i,
            name: i
        });
    }

    // Synchronize the values from the query string and the models
    $scope.dateFrom = UrlService.getUrlParameter('from');
    $scope.dateTo = UrlService.getUrlParameter('to');
    $scope.guests = UrlService.getUrlParameter('guests');

    $scope.$watch('dateFrom', function() {
        UrlService.updateParameter('from', $scope.dateFrom || '');
    });

    $scope.$watch('dateTo', function() {
        UrlService.updateParameter('to', $scope.dateTo || '');
    });

    $scope.$watch('guests', function() {
        UrlService.updateParameter('guests', $scope.guests || 0);
    });
};

module.exports = [
    '$rootScope',
    '$scope',
    'BookingsService',
    'UrlService',
    'appConfigs',
    BaseController
];