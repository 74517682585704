let InvoiceComponent = function(
    $sce,
    InvoicesService
) {
    let $ctrl = this;

    $ctrl.checkout = false;
    $ctrl.invoice = false;

    $ctrl.$onInit = () => {
        let urlSearch = new URLSearchParams(document.location.href);

        if (urlSearch.has('redirect') && urlSearch.get('redirect')) {
            $ctrl.redirected = true;
        }

        InvoicesService.read($ctrl.invoiceUid).then((res) => {
            $ctrl.invoice = res.data.data;
            $ctrl.invoice.url_checkout = $sce.trustAsResourceUrl(
                $ctrl.invoice.url_checkout
            );
        }, res => {
            $ctrl.invoice = null;
        });
    }
};

module.exports = {
    bindings: {
        invoiceUid: '@',
    },
    controller: [
        '$sce',
        'InvoicesService',
        InvoiceComponent
    ],
    templateUrl: '/assets/tpl/pages/invoice-component.html'
};