let UrlService = function() {
    return new (function() {

        this.getUrlParameter = function getUrlParameter(sParam) {
            var sPageURL = window.location.search.substring(1),
                sURLVariables = sPageURL.split('&'),
                sParameterName,
                i;
        
            for (i = 0; i < sURLVariables.length; i++) {
                sParameterName = sURLVariables[i].split('=');
        
                if (sParameterName[0] === sParam) {
                    return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
                }
            }
        };

        this.updateParameter = function (key, value) {
            var uri = window.location.href,
                re = new RegExp("([?&])" + key + "=.*?(&|$)", "i"),
                separator = uri.indexOf('?') !== -1 ? "&" : "?";
            
            if (uri.match(re)) {
                uri = uri.replace(re, '$1' + key + "=" + value + '$2');
                history.pushState({}, null, uri);
            }
            else {
                uri = uri + separator + key + "=" + value;
            }
            
            history.pushState({}, null, uri);
        }
    });
};

module.exports = [
    'ApiRequest',
    UrlService
];