let AccommodationTypesService = function(ApiRequest) {
    let uriPrefix = '/accommodation-types';

    return new (function() {
        this.index = function(data) {
            return ApiRequest.get(
                uriPrefix, data
            );
        };
    });
};

module.exports = [
    'ApiRequest',
    AccommodationTypesService
];