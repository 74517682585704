let InvoicesService = function(
    ApiRequest,
    $filter
) {
    let uriPrefix = '/invoices';
    let trans = (key, prefix = 'booking.sidebar_accommodation_') => {
        return $filter('translate')(prefix + key);
    };

    return new(function() {
        this.read = function(uid) {
            return ApiRequest.get(
                uriPrefix + '/' + uid
            );
        };

        this.orderToInvoice = (order, activities) => {
            let invoice = {
                invoice_number: trans('booking.invoice_order_overview', ''),
                date_formatted: moment().format('YYYY.MM.DD'),
                items: [],
                state: 'pending',
            };

            let btw = {
                totals: {
                    price_inclusive: 0,
                    price_exclusive: 0,
                    btw: {},
                },
                add: function(size, price) {
                    if (!this.totals.btw[size]) {
                        this.totals.btw[size] = 0;
                    }

                    this.totals.price_inclusive += price.price_inclusive;
                    this.totals.price_exclusive += price.price_exclusive;
                    this.totals.btw[size] += price.btw;
                }
            };

            order.accommodations.forEach(item => {
                item.options.forEach(option => {
                    if (!option.initial_price) {
                        option.price *= option.amount * item.price.invoice.quantity;
                    }
                    option.initial_price = option.price;
                })

                invoice.items.push({
                    name: item.accommodation.name,
                    type: 'booking_accommodation',
                    price: item.price,
                    quantity: item.price.invoice.quantity,
                    quantity_description: item.price.invoice.quantity_description,
                    sub_items: item.options,
                    description: [
                        item.adults > 0 ? (item.adults) + ' ' + trans('adults') : false,
                        item.teens > 0 ? (item.teens) + ' ' + trans('teens') : false,
                        item.kids > 0 ? (item.kids) + ' ' + trans('kids') : false,
                    ].filter(item => item).concat(
                        item.options.map(option => option.amount + ' ' + option.name)
                    ).join(', '),
                });
            });

            activities.filter(
                activity => activity.active
            ).forEach(activity => {
                invoice.items.push({
                    name: activity.name,
                    price: activity.order.price,
                    quantity: activity.order.price.invoice.quantity,
                    quantity_description: activity.order.price.invoice.quantity_description,
                    type: 'booking_activity',
                    description: [
                        activity.order.people + ' ' + trans('booking.sidebar_activities_guests', ''),
                    ].join(', '),
                });
            });

            activities.filter(
                activity => activity.requested
            ).forEach(activity => {
                invoice.items.push({
                    name: activity.name,
                    price: '',
                    quantity: '',
                    quantity_description: '',
                    type: 'booking_activity',
                    description: '',
                    price_on_request: true
                });
            });

            order.accommodations.forEach(accommodation => btw.add(
                accommodation.accommodation.btw,
                accommodation.price
            ));

            activities.filter(
                activity => activity.active
            ).forEach(activity => btw.add(
                activity.btw,
                activity.order.price
            ));

            invoice.btw = btw;

            return invoice;
        };
    });
};

module.exports = [
    'ApiRequest',
    '$filter',
    InvoicesService
];