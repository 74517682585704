require('./angular-1/angular-app');
require('./lazy-loader');

$.ajaxSetup({
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

$(document).ready(function() {
    $('.nano').nanoScroller({
        alwaysVisible: true
    });

    var getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
    };

    // slider1
    $('.slider1').slick({
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        responsive: [
            {
                breakpoint: 1301,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 620,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    //quote slider
    $('.slider2').slick({
        autoplay:true,
        autoplaySpeed:5000,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true
    });

    //inner content slider
    $('.slider3-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        asNavFor: '.slider3-nav'
    });

    $('.slider3-nav').slick({
        infinite: false,
        slidesToShow: 6,
        asNavFor: '.slider3-for',
        arrows: false,
        dots: false,
        centerMode: false,
        focusOnSelect: true,
        centerPadding: '10%',
        swipe: false,
        swipeToSlide: false,
        useTransform: false,
        waitForAnimate: false,
        responsive: [
            {
                breakpoint: 1301,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 620,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    });

    //search btn
    $('.main-search-btn').on('click', function(e) {
        $('.search-header').toggleClass('active');

        if (!$(this).hasClass('mobile')) {
            $(document).mouseup(function(e) {
                var search = $(".search-header");
                if (!search.is(e.target)
                    && search.has(e.target).length === 0) {
                    $('.search-header').removeClass('active');
                }
            });
        }
    });

    //menu
    $('.burger').on('click', function() {
        $('body').addClass('open-menu');
        $('.menu-wrapper').addClass('active');
        $(this).hide();
    });

    //menu
    $('.menu-close').on('click', function() {
        $('body').removeClass('open-menu');
        $('.menu-wrapper').removeClass('active');
        $('.burger').show();
        $('.search-header').removeClass('active');
    });

    $('.with-subnav').click(function() {
        if ($(this).hasClass('has-subnav')) {
            $(this).toggleClass('active');

            $(this).find('.submenu-toggle').
                toggleClass('mdi-chevron-down').
                toggleClass('mdi-chevron-up');

            $(this).find('.subnav-menu').toggleClass('hidden');    
        }
    });

    $('#mobile-language-list li').click(function() {
        let me = $(this);

        $('#mobile-language-list li').removeClass('checked');
        me.addClass('checked');

        setTimeout(function() {
            document.location.href = me.data('href');
        }, 1000);
    });

    //form place
    $('.choose-step.step2 .pclose-btn').on('click', function() {
        $(this).parent().remove();
    });

    //calendar btn
    var condition = $('.calendar-btn');

    condition.on('click', function(){
        $(this).parent().addClass('active').siblings().removeClass('active').parent().parent().siblings().find('.button-block').removeClass('active');
    });
    
    $(document).mouseup(function (e) {
        if (!condition.is(e.target) && condition.has(e.target).length === 0) {
            condition.parent().removeClass('active');
        }
    });

    //custom select
    $('.select-box select').each(function () {
        var $this = $(this), numberOfOptions = $(this).children('option').length;

        $this.addClass('select-hidden');
        $this.wrap('<div class="select"></div>');
        $this.after('<div class="select-styled"></div>');

        var $styledSelect = $this.next('div.select-styled');
        $styledSelect.text($this.children('option').eq(0).text());

        var $list = $('<ul />', {
            'class': 'select-options'
        }).insertAfter($styledSelect);

        for (var i = 0; i < numberOfOptions; i++) {
            $('<li />', {
                text: $this.children('option').eq(i).text(),
                rel: $this.children('option').eq(i).val()
            }).appendTo($list);
        }

        var $listItems = $list.children('li');

        $styledSelect.click(function (e) {
            e.stopPropagation();
            $('div.select-styled.active').not(this).each(function () {
                $(this).removeClass('active').next('ul.select-options').hide();
            });
            $(this).toggleClass('active').next('ul.select-options').toggle();
        });

        $listItems.click(function (e) {
            e.stopPropagation();
            $styledSelect.text($(this).text()).removeClass('active');
            $this.val($(this).attr('rel'));
            $list.hide();
            //console.log($this.val());
        });

        $(document).click(function () {
            $styledSelect.removeClass('active');
            $list.hide();
        });
    });


    //add input
    $(document).on('click', '.qty-plus', function() {
        $(this).prev().val(+$(this).prev().val() + 1);
    });

    $(document).on('click', '.qty-minus', function() {
        if ($(this).next().val() > 0) $(this).next().val(+$(this).next().val() - 1);
    });

    //choose tabs
    $('.tab_content').hide();
    $('.tab_content:first').show();
    $('.tabs li:first').addClass('active');
    $('.tabs li a').click(function(event) {
        event.preventDefault();
    });

    $('.tabs li').click(function(event) {
        $('.tabs li').removeClass('active');
        $(this).addClass('active');
        $('.tab_content').hide();
        var selectTab = $(this).find('a').attr("href");
        $(selectTab).fadeIn();
    });

    var hasChosenStar = false,
        $starsInput = $('.form-wrapper').find('form [name="stars"]'),
        starsSelector = '.give-rating .star';

    if ($starsInput.length && $starsInput.val()) {
        setActiveStars($starsInput.val());
    }

    function setActiveStars(lastIndex) {
        $(starsSelector).each(function() {
            var starImage = '/assets/img/' + ($(this).data('index') <= lastIndex ?
                'star-active.png' : 'star-normal.png');

            $(this).attr('src', starImage);
        });
    }

    $(document).on('mouseover', starsSelector, function() {
        setActiveStars($(this).data('index'));
    });

    $(document).on('mouseout', starsSelector, function() {
        setActiveStars($starsInput.val());
    });

    $(document).on('click', starsSelector, function() {
        setActiveStars($(this).data('index'));

        $starsInput.val($(this).data('index'));

        hasChosenStar = true;
    });

    $(document.links).filter(function() {
        return $(this).closest('.block-markdown').length && this.hostname != window.location.hostname;
    }).attr('target', '_blank');

    $("form#newsletter-add").submit(function(event) {
        event.preventDefault();

        let $form = $(this);

        $form.submitForm({
            url: $form.attr('action'),
            dataType: 'json',
            type: 'POST',
            noModal: true
        });

        return false;
    });

    $("form#contact").submit(function(event) {
        let $form = $(this);

        let response = grecaptcha.getResponse();
        if(response.length == 0) {
            $form.find('#g-recaptcha-error').removeClass('hidden');
            return false;
        } else {
            $form.find('#g-recaptcha-error').addClass('hidden');
        }
        
        return true;
    });

    $('.close-btn').click(function() {
        $('.block-cookie-consent').hide();

        $.ajax({
            'method': 'GET',
            'url': '/cookie-consent/reject/',
        }).done(function(response) {
            //
        }).fail(function(response) {
            //
        });
    });

    $('.block-cookie-consent .apply').click(function() {
        $('.block-cookie-consent').hide();

        $.ajax({
            'method': 'GET',
            'url': '/cookie-consent/apply/',
        }).done(function(response) {
            //
        }).fail(function(response) {
            //
        });
    });

    $('.block-cookie-consent .cancel').click(function() {
        $('.block-cookie-consent').hide();

        $.ajax({
            'method': 'GET',
            'url': '/cookie-consent/reject/',
        }).done(function(response) {
            //console.log(response);
        }).fail(function(response) {
            //console.log(response);
        });
    });
});

$.prototype.menuSwitcher = function() {
    if (this.length == 0) {
        return;
    }

    let menuSwitcher = function($root) {
        let $toggle = $root.find('[menu-switcher-toggle]');
        let $menus = $root.find('[menu-switcher-menu] .menu');
        let index = -1;

        if ($($menus[0]).hasClass('hidden')) {
            index++;
        }

        let toggle = (event) => {
            if (index >= $menus.length - 1) {
                index = 0;
            } else {
                index++;
            }

            $menus.hide();
            $toggle.hide();

            $($menus[index]).show();
            $($toggle[index]).show();

            if(typeof event != 'undefined') {
                let url = $(event.target).data('link');

                document.location.href = url;
            }
        };

        toggle();
        /* if (screen.width > 1000) {
        } */

        $toggle.unbind('click.menuToggle').bind('click.menuToggle', toggle);
    };

    for (let index = 0; index < this.length; index++) {
        new menuSwitcher($(this[index]));
    }
};

// $('[menu-switcher]').menuSwitcher();

$('#show-more').click(function() {
    $('.comments-wrapper.more').toggleClass('hide');
});