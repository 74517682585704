let i18nTranslations = {};
const app = angular.module('hayemaHeerd', ['slickCarousel', 'pascalprecht.translate', 'ngCookies', '720kb.datepicker']);
const appConfigs = {
    api_url: env_data.apiUrl,
    i18nTranslations: i18nTranslations
};

app.constant('appConfigs', appConfigs);

// Controllers
app.controller('BaseController', require('./controllers/BaseController'));
app.controller('ContactsController', require('./controllers/ContactsController'));
app.controller('HomeDateSelectorController', require('./controllers/HomeDateSelectorController'));

// Components
app.component('offersComponent', require('./components/OffersComponent'));
app.component('bookingComponent', require('./components/BookingComponent'));
app.component('giftCardComponent', require('./components/GiftCardComponent'));
app.component('invoiceComponent', require('./components/InvoiceComponent'));

// Modal Components
app.component('modalCartComponent', require('./components/Modals/Offers/ModalCartComponent'));
app.component('modalCartQuantityComponent', require('./components/Modals/Offers/ModalCartQuantityComponent'));
app.component('modalCartGiftCardEditorComponent', require('./components/Modals/Offers/ModalCartGiftCardEditorComponent'));
app.component('modalAccommodationPopupComponent', require('./components/Modals/ModalAccommodationPopupComponent'));

// Services
app.service('AccommodationTypesService', require('./services/AccommodationTypesService'));
app.service('InvoicesService', require('./services/InvoicesService'));
app.service('AccommodationsService', require('./services/AccommodationsService'));
app.service('ActivitiesService', require('./services/ActivitiesService'));
app.service('GiftCardsService', require('./services/GiftCardsService'));
app.service('BookingsService', require('./services/BookingsService'));
app.service('CountryService', require('./services/CountryService'));
app.service('ModalService', require('./services/ModalService'));
app.service('OfferService', require('./services/OfferService'));
app.service('CustomOfferService', require('./services/CustomOfferService'));
app.service('DateService', require('./services/DateService'));
app.service('InterestsService', require('./services/InterestsService'));

app.service('UrlService', require('./services/UrlService'));
app.service('FormBuilderService', require('./services/FormBuilderService'));

// Providers
app.provider('ApiRequest', require('./providers/ApiRequestProvider'));
app.provider('ModalRoute', require('./providers/ModalRouteProvider'));

// Filters
app.filter('percentage_format', require('./filters/PercentageFormatFilter'));
app.filter('currency_format', require('./filters/CurrencyFormatFilter'));
app.filter('pretty_json', require('./filters/PrettyJsonFilter'));
app.filter('to_fixed', require('./filters/ToFixedFilter'));

// Directives
app.directive('datepickerDirective', require('./directives/DatepickerDirective'));
app.directive('blockInvoice', require('./directives/InvoiceDirective'));
app.directive('calendarDirective', require('./directives/CalendarDirective'));
app.directive('clickOutside', require('./directives/ClickOutsideDirective'));

app.directive('modalsRoot', require('./directives/modals/ModalsRootDirective'));
app.directive('modalItem', require('./directives/modals/ModalItemDirective'));
app.directive('modalScrollBraker', require('./directives/modals/ModalScrollBrakerDirective'));

// Config
app.config(require('./routers/modals'));
app.config(require('./config/api-service'));
app.config(require('./config/i18n'));
app.config(['$compileProvider', function($compileProvider) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|callto|data:img\/png;base64|javascript):/);
}])

$.get('/api/public/translations?per_page=100000').done((res) => {
    res.data.map(transItem => {
        if (typeof i18nTranslations[transItem.locale] != 'object') {
            i18nTranslations[transItem.locale] = {};
        }

        if (typeof i18nTranslations[transItem.locale][transItem.group] != 'object') {
            i18nTranslations[transItem.locale][transItem.group] = {};
        }

        i18nTranslations[transItem.locale][transItem.group][transItem.item] = transItem.text;
    });

    // Bootstrap the app
    angular.bootstrap(document.querySelector('html'), ['hayemaHeerd']);
});

module.exports = app;