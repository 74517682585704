let GiftCardsService = function(
    ApiRequest
) {
    let uriPrefix = '/gift-cards';

    return new(function() {
        this.read = function(code) {
            return ApiRequest.get(
                uriPrefix + (code ? ('/' + code) : '')
            );
        };
    });
};

module.exports = [
    'ApiRequest',
    GiftCardsService
];