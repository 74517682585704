let RouteComponent = function(
    $scope,
    $window,
    FormBuilderService
) {
    let $ctrl = this;
    let defaultSettings = {
        mapCenterCoordinates: {
            lat: 53.3242496,
            lng: 6.4160059
        },
        address: {
            street: 'Jensemaweg 3',
            postalCode: '9883 TH Oldehove',
            lat: 53.2842496,
            lng: 6.4160059
        },
        googleMapsKey: null,
        mapZoom: 10
    };

    $ctrl.viewRoute = false;

    $ctrl.routeDistance = {
        show: false,
        time: '',
        distance: ''
    };

    $ctrl.routeForm = FormBuilderService.build({}, (form) => {
        var address = [
            form.values.address,
            form.values.city,
            form.values.country
        ].join(" ");

        $ctrl.routeForm.invalid = false;

        $.ajax({
            method: 'GET',
            url: 'https://maps.google.com/maps/api/geocode/json',
            data: {
                address: address,
                key: googleMapsKey
            },
            success: function(response) {
                if (response.status === 'OK' && response.results.length) {
                    var coord = response.results[0].geometry.location;
                    console.log(map);

                    myPos.lat = coord.lat,
                        myPos.lng = coord.lng;

                    var marker_from = new google.maps.Marker({
                        map: map,
                        //draggable: true,
                        animation: google.maps.Animation.DROP,
                        //label: 'You',
                        position: { lat: myPos.lat, lng: myPos.lng },
                        icon: 'assets/img/user-marker.png'
                    });

                    var directionsService = new google.maps.DirectionsService;
                    var directionsDisplay = new google.maps.DirectionsRenderer({
                        draggable: true,
                        map: map,
                        panel: document.getElementById('right-panel'),
                        suppressMarkers: true
                    });

                    directionsDisplay.addListener('directions_changed', function() {
                        computeTotalDistance(directionsDisplay.getDirections());
                    });

                    displayRoute(
                        address,
                        street + ' ' + postalCode,
                        directionsService,
                        directionsDisplay,
                        address,
                        street + ' ' + postalCode
                    );

                    $('#find-location').hide();
                    //$('#show-info').show();
                    $('#map-info').show();
                } else {
                    $scope.$apply(() => {
                        $ctrl.routeForm.invalid = true;
                    });
                }
            },
            error: () => {
                $ctrl.routeForm.invalid = true;
            }
        });
    });

    $ctrl.showRoute = () => {
        $ctrl.viewRoute = true;
    };

    $ctrl.showForm = () => {
        $ctrl.viewRoute = false;
        $ctrl.routeForm.values.address = '';
        $ctrl.routeForm.values.city = '';
        $ctrl.routeForm.values.country = '';
    };

    var contacts = typeof cms_config != 'undefined' ? cms_config : {},
        street = contacts.street ? contacts.street : defaultSettings.address.street,
        postalCode = contacts.postal_code ? contacts.postal_code : defaultSettings.address.postalCode,
        googleMapsKey = contacts.googleMapsKey ? contacts.googleMapsKey : defaultSettings.address.googleMapsKey,
        myPos = {
            lat: 52.36,
            lng: 4.913,
        },
        map;


    function displayRoute(origin, destination, service, display, point1, point2) {
        service.route({
            origin: origin,
            destination: destination,
            waypoints: [{ location: point1 }, { location: point2 }],
            travelMode: 'DRIVING',
            avoidTolls: true
        }, function(response, status) {
            if (status === 'OK') {
                display.setDirections(response);
            } else {
                alert('Could not display directions due to: ' + status);
            }
        });
    }

    function computeTotalDistance(result) {
        var totalDistance = 0,
            totalTime = 0,
            myroute = result.routes[0];

        for (var i = 0; i < myroute.legs.length; i++) {
            totalDistance += myroute.legs[i].distance.value;

            if (myroute.legs[i].duration.value > 0) {
                totalTime = myroute.legs[i].duration.text;
            }
        }

        totalDistance = totalDistance / 1000;

        $ctrl.routeDistance.distance = totalDistance;
        $ctrl.routeDistance.time = totalTime;
    }

    $ctrl.init = () => {
        if (document.getElementById('map')) {
            $.ajax({
                method: 'GET',
                url: 'https://maps.google.com/maps/api/geocode/json',
                data: {
                    address: street + ' ' + postalCode,
                    key: googleMapsKey
                },
                success: function(response) {
                    var lat = defaultSettings.address.lat,
                        lng = defaultSettings.address.lng;

                    if (response.status === 'OK' && response.results.length) {
                        var coord = response.results[0].geometry.location;

                        lat = coord.lat;
                        lng = coord.lng;
                    }

                    initMap(lat, lng);
                }
            });
        }

        function initMap(lat, lng) {
            if (typeof google == 'undefined') {
                return;
            }

            map = new google.maps.Map(document.getElementById('map'), {
                center: {
                    lat: defaultSettings.mapCenterCoordinates.lat,
                    lng: defaultSettings.mapCenterCoordinates.lng
                },
                zoom: defaultSettings.mapZoom,
                styles: [{
                    "featureType": "landscape",
                    "stylers": [{
                        "hue": "#FFAD00"
                    }, {
                        "saturation": 50.2
                    }, {
                        "lightness": -34.8
                    }, {
                        "gamma": 1
                    }]
                }, {
                    "featureType": "road.highway",
                    "stylers": [{
                        "hue": "#FFAD00"
                    }, {
                        "saturation": -19.8
                    }, {
                        "lightness": -1.8
                    }, {
                        "gamma": 1
                    }]
                }, {
                    "featureType": "road.arterial",
                    "stylers": [
                        {
                            "hue": "#FFAD00"
                        }, {
                            "saturation": 72.4
                        }, {
                            "lightness": -32.6
                        }, {
                            "gamma": 1
                        }]
                }, {
                    "featureType": "road.local",
                    "stylers": [{
                        "hue": "#FFAD00"
                    }, {
                        "saturation": 74.4
                    }, {
                        "lightness": -18
                    }, {
                        "gamma": 1
                    }]
                }, {
                    "featureType": "water",
                    "stylers": [{
                        "hue": "#00FFA6"
                    }, {
                        "saturation": -63.2
                    }, {
                        "lightness": 38
                    }, {
                        "gamma": 1
                    }]
                }, {
                    "featureType": "poi",
                    "stylers": [{
                        "hue": "#FFC300"
                    }, {
                        "saturation": 54.2
                    }, {
                        "lightness": -14.4
                    }, {
                        "gamma": 1
                    }]
                }
                ]
            });


            //Add 'Find My Route' control
            var routeControlDiv = document.createElement('div');

            routeControlDiv.setAttribute("id", "find-location");
            routeControlDiv.style["padding-top"] = "10px";

            routeControlDiv.index = 1;
            map.controls[google.maps.ControlPosition.TOP_CENTER].push(routeControlDiv);

            var marker = new google.maps.Marker({
                map: map,
                //draggable: true,
                animation: google.maps.Animation.DROP,
                //label: 'Us',
                position: { lat: lat, lng: lng },
                icon: '/assets/img/map-marker.png'
            });

            var infoWindow = new google.maps.InfoWindow;
        }
    };

    $ctrl.init();

    $scope.$ctrl = $ctrl;
};

module.exports = [
    '$scope',
    '$window',
    'FormBuilderService',
    RouteComponent
];