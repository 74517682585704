let InvoiceDirective = function($scope, $element) {

    $scope.printInvoice = () => {
        let $invoiceBlock = $($element[0].innerHTML);
        $invoiceBlock.addClass('print-show');
        
        $('body>*').addClass('print-hide');
        $('body').append($invoiceBlock);
        
        window.print();

        $invoiceBlock.remove();
        $('.print-hide').removeClass('print-hide');
    }
};

module.exports = () => {
    return {
        restrict: 'EA',
        scope: {
            'scroller': '=',
            'invoice': '=',
            'ctrl': '=',
            'invoiceResource': '='
        },
        controller: [
            '$scope',
            '$element',
            InvoiceDirective
        ],
        templateUrl: './assets/tpl/directives/invoice-directive.html'
    };
};