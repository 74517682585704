let AccommodationsService = function(ApiRequest) {
    let uriPrefix = '/accommodations';

    return new (function() {
        this.index = function(data) {
            return ApiRequest.get(
                uriPrefix, data
            );
        };

        this.indexAll = function(data) {
            return ApiRequest.get(
                uriPrefix + '/all', data
            );
        };
        
        this.indexPost = function(data) {
            return ApiRequest.post(
                uriPrefix + '/index', data
            );
        };
    });
};

module.exports = [
    'ApiRequest',
    AccommodationsService
];