let BookingComponent = function(
    $q,
    $http,
    $filter,
    $timeout,
    $element,
    UrlService,
    DateService,
    BookingsService,
    GiftCardsService,
    ActivitiesService,
    InterestsService,
    AccommodationsService,
    InvoicesService,
    CountryService,
    AccommodationTypesService,
    FormBuilderService,
    ModalService
) {
    let $ctrl = this;
    let $calendar = null;
    let $translate = $filter('translate');
    let jsonClone = value => JSON.parse(JSON.stringify(value));

    let objOnly = (obj, keys) => {
        let out = {};

        keys.forEach(key => obj.hasOwnProperty(key) && (out[key] = obj[key]));

        return out;
    }
    
    $ctrl.max_accommodations = 2;
    $ctrl.disableActivities = true;

    $ctrl.dateFormat = 'dd-MM-yyyy';
    $ctrl.termsAccepted = false;
    $ctrl.giftCardCode = UrlService.getUrlParameter('code') || '';
    $ctrl.giftCardState = 'form';
    $ctrl.giftCardSavings = 0;

    $ctrl.activities = [];
    $ctrl.interests = [];
    $ctrl.accommodations = [];
    $ctrl.reservation_type = 'reservation';
    $ctrl.totalPrice = {
        price_inclusive: 0,
        price_exclusive: 0,
        price_btw: 0,
    };

    $ctrl.minStartDate = moment().toDate().toDateString();
    $ctrl.minEndDate = moment().add(1, 'days').toDate().toDateString();
    $ctrl.minActivityDate = moment().toDate().toDateString();
    $ctrl.maxDate = moment().add(9, 'months').toDate().toDateString();

    $ctrl.invoice = false;
    
    $ctrl.die = () => {
        alert('Something went wrong, please, try again!');
        document.location.reload();
    };

    $ctrl.checkForInvalidOptions = (respData) => {
        if (respData) {
            for(let errKey in respData) {
                if (errKey.indexOf('accommodations.') !== -1 && 
                    errKey.indexOf('options') !== -1
                ) {
                    $ctrl.die();
                    break;
                }
            }
        }
    }

    $ctrl.calcAccommodationPrice = (accommodation) => {
        let values = objOnly(accommodation, [
            'accommodation_id', 'options', 'adults', 'teens', 'kids',
            'start_date', 'end_date'
        ]);

        return $q((resolve, reject) => {
            // Test for invalid option values
            // if (values && values.options && values.options[0]) {
            //     values.options[0].accommodation_option_id = 1213123;
            // }

            BookingsService.calcPrice({
                gift_card_code: $ctrl.giftCardCode,
                activities: $ctrl.getActiveActivities(),
                accommodations: $ctrl.order.accommodations.concat([values])
            }).then((res) => {
                resolve(res.data.accommodations[
                    res.data.accommodations.length - 1
                ]);
            }, (res) => {
                $ctrl.checkForInvalidOptions(res.data.errors);
                reject(res);
            });
        });
    };

    $ctrl.calcBookigPrice = () => {
        return $q((resolve, reject) => {
            return BookingsService.calcPrice({
                gift_card_code: $ctrl.giftCardCode,
                activities: $ctrl.getActiveActivitiesForOrder(),
                accommodations: $ctrl.order.accommodations
            }).then(function(res) {
                $ctrl.getActiveActivities().forEach((activity, index) => {
                    activity.order.price = res.data.activities[index];
                });
                $ctrl.order.accommodations.forEach((accommodation, index) => {
                    accommodation.price = res.data.accommodations[index];

                    let options = res.data.accommodations[index].options;
                    if (options) {
                        accommodation.options.forEach((option) => {
                            options.forEach((_option) => {
                                if (_option['accommodation_option_id'] == option['accommodation_option_id']) {
                                    option.price = _option.price;
                                }
                            });
                        });
                    }
                });

                $ctrl.updateTotalPrice();

                resolve(res);
            }, (res) => {
                $ctrl.checkForInvalidOptions(res.data.errors);
                reject(res);
            });
        })
    };

    // start
    $ctrl.indexOfOption = (option) => {
        return $ctrl.accommodationForm.values.options.indexOf(option);
    };

    $ctrl.indexOfOptionById = (option) => {
        return $ctrl.accommodationForm.values.options.indexOf(
            $ctrl.accommodationForm.values.options.filter(
                _option => _option.accommodation_option_id == option
            )[0]
        );
    };

    $ctrl.toggleOption = (option) => {
        if ($ctrl.indexOfOptionById(option.id) == -1) {
            $ctrl.addOption(option);
        } else {
            $ctrl.removeOption(option.id);
        }

        $ctrl.accommodationForm.calcAccommodationPrice();
    };

    $ctrl.addOption = (option) => {
        let get_person_trans = (item, option) => {
            let plural, singular;

            if (typeof option.translations[$ctrl.locale] != 'undefined') {
                plural = option.translations[$ctrl.locale].plural;
                singular = option.translations[$ctrl.locale].singular;
            }

            return (item + option.amount_min > 1) ? 
                    (plural ? ' ' + plural : ''): 
                    (singular ? ' ' + singular : '')
        };

        let amountOptions = [
            ...Array((option.amount_max - option.amount_min) + 1).keys()
        ].map((item) => ({
            id: item + option.amount_min,
            name: item + option.amount_min + get_person_trans(item, option)
        }));

        let defaultAmount = option.amount_max < $ctrl.accommodationForm.values.adults ?
            option.amount_max : $ctrl.accommodationForm.values.adults;

        $ctrl.accommodationForm.values.options.push({
            accommodation_option_id: option.id,
            amount: defaultAmount, //option.amount_min,
            price: option.price,
            name: option.name,
            amount_max: option.amount_max,
            amountOptions: amountOptions,
        });
    };

    $ctrl.removeOption = (id) => {
        let options = $ctrl.accommodationForm.values.options.filter(
            option => option.accommodation_option_id == id
        );

        options.forEach(option => {
            $ctrl.accommodationForm.values.options.splice($ctrl.indexOfOption(option), 1);
        });
    };
    // end

    $ctrl.order = {
        client: {},
        accommodations: [],
        activities: [],
    };

    $ctrl.eventColorsIndex = -1;
    $ctrl.eventColors = [
        '#58d09f', '#d4454e', '#e9d472', '#afb6bf', '#6fbec0', '#9bc265',
        '#6a61f4', '#188473',
    ];

    $ctrl.errorMessage = false;

    $ctrl.showError = (title, messages, reload = false) => {
        $ctrl.errorMessage = {
            title: title,
            messages: messages,
            reload: reload,
        };
    };

    $ctrl.closeError = () => {
        if ($ctrl.errorMessage && $ctrl.errorMessage.reload) {
            document.location.reload();
        }

        $ctrl.errorMessage = false;
    };

    $ctrl._objectOnly = (obj = {}, keys = [], out = {}) => {
        keys.forEach(key => out[key] = obj[key]);
        return out;
    };

    $ctrl.rand = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    $ctrl.makeId = () => {
        return $ctrl.rand(100000, 999999);
    };

    $ctrl.makeIdUniq = (arr, key = 'id') => {
        let id;

        do {
            id = $ctrl.makeId();
        } while (arr.filter(el => el[key] == id).length > 0);

        return id;
    };

    $ctrl.updateTotalPrice = () => {
        $ctrl.updateInvoice();
        $ctrl.updateNanoScroller();
    };

    $ctrl.updateNanoScroller = () => {
        $timeout(() => {
            $($element.find('.nano')).nanoScroller({
                alwaysVisible: true
            });
        }, 50);
    };

    $ctrl.goState = (state, params) => {
        $ctrl.state = state;
        $ctrl.updateTotalPrice();
        $ctrl.updateNanoScroller();

        switch (state) {
            case 'add_accommodation':
                $ctrl.onAccommodation();
                break;
            case 'personal_info':
                $ctrl.onActivities();
                $ctrl.onInterests();
                $ctrl.onPersonalInfo();
                $ctrl.onInvoicePreview();
                break;
            case 'confirmation':
                $ctrl.onConfirmation();
                break;
        }

        $ctrl.onConfirmation();
    }

    $ctrl.selectActivity = (activity) => {
        let prevDate = activity.order.date || null;
        activity.active = true;

        // lazy initialization
        $timeout(() => {
            activity.order.date = prevDate || null;
        }, 100);

        let date = DateService._dateParse(
            activity.order.date
        ).format('YYYY-MM-DD');

        $ctrl.calendarAddEvent({
            type: 'activity',
            activityId: activity.id,
            color: activity.color,
            title: activity.name,
            start: date,
            end: date,
        });

        $ctrl.calcBookigPrice();
    };

    $ctrl.removeActivity = (activity) => {
        $ctrl.calendarDeleteEvents(event => (
            event.type == 'activity' && event.activityId == activity.id
        ));

        activity.active = false;
        $ctrl.updateTotalPrice();
    };

    $ctrl.onActivities = () => {
        let old_activities = JSON.parse(JSON.stringify($ctrl.activities));

        return ActivitiesService.index({
            dates: $ctrl.getAccommodationsDates()
        }).then((res) => {
            let activities = res.data.data.map((activity) => {
                let datesAvailable = DateService._dateArrayParse(
                    activity.datesAvailable
                );

                activity.color = $ctrl.calendarNextEventColor();
                activity.order = {
                    people: activity.people_min,
                    activity_id: activity.id,
                    date: activity.datesAvailable[0],
                    datesAvailable: datesAvailable,
                    peopleOptions: [
                        ...Array(activity.people_max - (
                            activity.people_min - 1
                        )).keys()
                    ].map(item => item + activity.people_min)
                };

                return activity;
            });
            // Apply previous order values
            activities.map(function(activity) {
                let oldItem = old_activities.filter(
                    item => item.id == activity.id
                )[0] || null;

                if (oldItem && activity.datesAvailable.indexOf(
                        oldItem.order.date
                    ) != -1 && activity.order.peopleOptions.indexOf(
                        oldItem.order.people
                    ) != -1) {
                    activity.color = oldItem.color;
                    activity.active = oldItem.active;
                    activity.order.date = oldItem.order.date;
                    activity.order.price = oldItem.order.price;
                    activity.order.people = oldItem.order.people;
                } else if (oldItem) {
                    $ctrl.removeActivity(oldItem);
                }

                return activity;
            });

            $ctrl.activities = activities;
        });
    }

    $ctrl.onInterests = () => {
        return InterestsService.index().then((res) => {
            $ctrl.interests = res.data.data;
        });
    }

    $ctrl.onAccommodation = () => {
        $ctrl.accommodationForm = {
            accommodation_type_id: null,
            accommodations: [],
            accommodation: null,
            rangeValid: false,
            price: this.price = {
                price_inclusive: 0,
                price_exclusive: 0,
                price_btw: 0
            },
            values: {
                teens: 0,
                adults: 1,
                kids: 0,
                start_date: $ctrl.params.start_date || null,
                end_date: $ctrl.params.end_date || null,
                accommodation_id: null,
                options: []
            },
            calcAccommodationPrice: function() {
                if (!this.accommodation) {
                    return this.price = {
                        price_inclusive: 0,
                        price_exclusive: 0,
                        price_btw: 0
                    };
                }

                let values = JSON.parse(JSON.stringify(this.values));
                
                return $q((resolve, reject) => {
                    $ctrl.calcAccommodationPrice(values).then(price => {
                        this.price = price;
                        $ctrl.updateTotalPrice();
                        this.errors = [];
                        
                        resolve(price);
                    }, res => {
                        this.errors = [];

                        reject(this.errors.end_date = 'You cant select this end date');
                    });
                });
            },
            selectType: function(accommodation_type_id) {
                this.accommodation_type_id = accommodation_type_id;
                this.accommodations = $ctrl.accommodations.filter((accommodation) => {
                    return (
                        accommodation.accommodation_type_id == accommodation_type_id
                    );
                });

                $ctrl.updateNanoScroller();
            },
            configureAccommodation: function(accommodation) {
                this.accommodation = accommodation;
                this.values.accommodation_id = accommodation.id;
                this.values.adults = 1;
                this.values.kids = 0;
                this.values.teens = 0;

                let values = angular.copy(this.values);
                let _accommodation = angular.copy(accommodation);

                let spots = _accommodation.people_max - (
                    (values.adults ? values.adults : 0) +
                    (values.teens ? values.teens : 0) +
                    (values.kids ? values.kids : 0)
                );

                this.accommodation.regenerateOptions = () => {
                    this.accommodation.adultsOptions = [
                        ...Array(spots + values.adults + 0).keys()
                    ].map(item => ++item)
    
                    this.accommodation.kidsOptions = [
                        ...Array(spots + values.kids + 1).keys()
                    ];
    
                    this.accommodation.teensOptions = [
                        ...Array(spots + values.teens + 1).keys()
                    ];

                    // if (typeof this.values.options != 'undefined') {
                    //     this.values.options.forEach(option => {
                    //         let newAmount = option.amount_max < this.values.adults ?
                    //             option.amount_max : this.values.adults;

                    //         return option.amount = newAmount;
                    //     });
                    // }

                    this.calcAccommodationPrice();
                };

                this.accommodation.regenerateOptions();
            },
            clearAccommodation: function() {
                this.accommodation = null;
                this.values.accommodation_id = null;
                this.calcAccommodationPrice();
            },
            submitAccommodation: function() {
                let values = jsonClone(this.values);

                values.eventId = $ctrl.makeIdUniq($ctrl.order.accommodations);
                values.price = this.price;
                values.accommodation = this.accommodation;
                values.nights = Math.abs(DateService.diffInDays(
                    values.start_date,
                    values.end_date
                ));

                $ctrl.order.accommodations.push(values);
                $ctrl.calendarAddEvent({
                    type: 'accommodation',
                    id: values.eventId,
                    color: this.accommodation.color,
                    title: this.accommodation.name,
                    start: DateService._dateParse(
                        values.start_date).format('YYYY-MM-DD'),
                    end: DateService._dateParse(
                        values.end_date).add(1, 'days').format('YYYY-MM-DD'),
                });

                $ctrl.accommodationForm = {};
                $ctrl.goState('personal_info');
                $ctrl.calendarDeletePlaceholder();
            },
            rangeChanged: function() {
                let start_date = DateService._dateParse(this.values.start_date);
                let end_date = DateService._dateParse(this.values.end_date);

                this.rangeValid = start_date._isValid && end_date._isValid;

                if (start_date._isValid) {
                    $ctrl.minEndDate = start_date.clone().add(1, 'days').toDate().toDateString();
                    $ctrl.minActivityDate = start_date.toDate().toDateString();

                    if (!this.rangeValid || !end_date.isAfter(start_date)) {
                        this.values.end_date = DateService._dateFormat(
                            end_date = start_date.clone().add(1, 'days')
                        );
                    }
                }

                this.rangeValid = start_date._isValid && end_date._isValid;
                this.loadAccommodations();

                if (this.rangeValid) {
                    $ctrl.calendarAddPlaceholder(start_date, end_date);
                }

                if (start_date._isValid) {
                    $calendar.fullCalendar(
                        'changeView',
                        'month',
                        DateService._dateFormatYmd(start_date)
                    );
                }
            },
            loadAccommodations: function() {
                let self = this;

                if (!self.rangeValid) {
                    return false;
                }

                self.calcAccommodationPrice();
            },
            init: function() {
                this.rangeChanged();
            }
        };

        $ctrl.accommodationForm.init();
    };

    $ctrl.onDatePeriodChanged = () => {
        $ctrl.accommodationForm.rangeChanged();

        let start_date = moment($ctrl.accommodationForm.values.start_date, "DD-MM-YYYY");
        let end_date = moment($ctrl.accommodationForm.values.end_date, "DD-MM-YYYY");

        $ctrl.nrNights = end_date.diff(start_date, 'days');
    };

    $ctrl.getAccommodationsDates = () => {
        return DateService._dateArrayFormat(DateService._dateRangeArraysUniq(
            $ctrl.order.accommodations.map(
                accommodation => {
                    return DateService._datesToRangeArray(
                        DateService._dateParse(accommodation.start_date),
                        DateService._dateParse(accommodation.end_date).clone().add(1, 'days')
                    );
                }
            )
        ));
    };

    $ctrl.getActivitiesDates = () => {
        return $ctrl.getActiveActivities().map(activity => activity.order.date);
    };

    $ctrl.onPersonalInfo = () => {
        if ($ctrl.clientForm) {
            return;
        }

        $ctrl.clientForm = FormBuilderService.build({
            country_id: 163
        }, function(form) {
            BookingsService.validate('client', form.values).then((res) => {
                $ctrl.order.client = form.values;
                $ctrl.goState('confirmation');
            }, (res) => {
                form.errors = res.data.errors;
            });
        });
    };

    $ctrl.onInvoicePreview = () => {
        $ctrl.updateInvoice();

        if ($ctrl.giftCardForm) {
            return;
        }

        $ctrl.giftCardForm = FormBuilderService.build({
            code: $ctrl.giftCardCode
        }, form => {
            if (!form.values.code) {
                return $ctrl.resetGiftCardCode();
            }

            GiftCardsService.read(form.values.code).then((res) => {
                $ctrl.applyGiftCardCode(form.values.code)
            }, $ctrl.rejectGiftCardCode);
        });
    };

    $ctrl.onConfirmation = () => {
        $ctrl.allowReservation = $ctrl.order.accommodations.filter(accommodation => {
            return DateService.diffInDays(
                DateService._dateParse(accommodation.start_date).startOf('date'),
                moment().startOf('date')
            ) < 14;
        }).length == 0;

        if (!$ctrl.allowReservation) {
            $ctrl.reservation_type = 'payment';
        }
    };

    // Activities added to cart and should be payd on checkout
    $ctrl.getActiveActivities = () => {
        return $ctrl.activities.filter(activity => activity.active);
    };

    // Activities added to cart and should be payd on checkout
    $ctrl.getSelectedInterests = () => {
        return $ctrl.interests.filter(interest => interest.active);
    };

    // Activities added to wishlist but not have to be paid at checkout
    // Only a metion is sent to admin
    $ctrl.getRequestedActivities = () => {
        return $ctrl.activities.filter(activity => activity.requested);
    };

    $ctrl.getActiveActivitiesForOrder = () => {
        return $ctrl.getActiveActivities().map(activity => ({
            activity_id: activity.order.activity_id,
            date: activity.order.date,
            people: activity.order.people,
        }));
    };

    $ctrl.applyGiftCardCode = (code) => {
        let oldPrice = $ctrl.invoice.btw.totals.price_inclusive;

        $ctrl.giftCardCode = code;
        $ctrl.giftCardState = 'accepted';

        $ctrl.calcBookigPrice().then(() => {
            $ctrl.giftCardSavings =
                oldPrice - $ctrl.invoice.btw.totals.price_inclusive;
        });
    };

    $ctrl.resetGiftCardCode = () => {
        $ctrl.giftCardCode = '';
        $ctrl.giftCardState = 'form';
        $ctrl.giftCardSavings = 0;
        $ctrl.calcBookigPrice();
    };

    $ctrl.rejectGiftCardCode = () => {
        $ctrl.giftCardCode = '';
        $ctrl.giftCardState = 'rejected';
        $ctrl.giftCardSavings = 0;
    };

    $ctrl.updateInvoice = () => {
        $ctrl.invoice = InvoicesService.orderToInvoice(
            $ctrl.order,
            $ctrl.activities
        );

        $ctrl.totalPrice = $ctrl.invoice.btw.totals;

        if (typeof $ctrl.accommodationForm != 'undefined') {
            if (typeof $ctrl.accommodationForm.price != 'undefined') {
                $ctrl.totalPrice.price_inclusive +=
                    $ctrl.accommodationForm.price.price_inclusive;
            }
        }
    }

    $ctrl.removeAccommodation = (accommodation) => {
        let index = $ctrl.order.accommodations.map(
            accommodation => accommodation.accommodation_id
        ).indexOf(accommodation.accommodation_id);

        $ctrl.calendarDeleteEvents(event => (
            event.type == 'accommodation' && event.id == accommodation.eventId
        ));

        if (index !== -1) {
            $ctrl.order.accommodations.splice(index, 1);
        }

        if ($ctrl.order.accommodations.length == 0) {
            $ctrl.goState('add_accommodation');
        }

        let datesAvailable = $ctrl.getAccommodationsDates();

        $ctrl.getActiveActivities().forEach(activity => {
            if (datesAvailable.indexOf(activity.order.date) == -1) {
                $ctrl.removeActivity(activity);
            }
        });
    };

    $ctrl.payNow = () => {
        $ctrl.reserve(true);
    }

    $ctrl.reserve = (payNow = false) => {
        if ($ctrl.reserving) {
            return false;
        }

        $ctrl.reserving = true;

        let values = JSON.parse(JSON.stringify(Object.assign({
            locale: $ctrl.locale,
            gift_card_code: $ctrl.giftCardCode,
            activities: $ctrl.getActiveActivities().map(activity => {
                return {
                    activity_id: activity.order.activity_id,
                    date: activity.order.date,
                    people: activity.order.people,
                }
            }),
            interests: $ctrl.getSelectedInterests(),
            accommodations: $ctrl.order.accommodations,
        }, $ctrl.order.client)));

        values.notes = "- Client note:\n" + (
            values.notes ? values.notes : 'None'
        );
        
        values.notes += "\n\n- Requested activities:\n";
        values.notes += $ctrl.getRequestedActivities().map(
            activity => '√ ' + activity.name
        ).join("\n");

        BookingsService.store(values).then((res) => {
            $q((resolve) => {
                if ($ctrl.subscribeAccepted) {
                    $http.post('/api/newsletter', {
                        'email': $ctrl.order.client.email
                    }).then(resolve, resolve);
                } else {
                    resolve();
                }
            }).then(() => {
                if (payNow) {
                    $ctrl.reserving = false;
                    let url = res.data.data.reservation_invoice.url_checkout;
                    let form = $('<form action="' + url + '" method="post"></form>');
                    $('body').append(form);
                    form.submit();
                } else {
                    $ctrl.goState('reserved_message');
                }
            });
        }, (res) => {
            $ctrl.reserving = false;
            let messages = [
                res.data.errors.accommodations[0] || false,
                res.data.errors.activities[0] || false,
            ];

            $ctrl.showError('Please try again.', messages, true);
        });
    };

    $ctrl.calendarGetEvents = (type = false) => {
        let events = [];

        $calendar.fullCalendar('clientEvents', function(event) {
            if (type && (type == event.type)) {
                events.push(event);
            }
        });

        return events;
    }

    $ctrl.calendarInit = (event) => {
        let eventHandler = function(event, delta, revertFunc) {
            let cantDoThat = (key, data) => {
                revertFunc();
                $timeout(() => {
                    let _data = {
                        name: data.title,
                        start_date: DateService._dateFormat(data.start),
                        end_date: DateService._dateFormat(data.end || data.start),
                    };

                    $ctrl.showError(
                        $translate('booking.calendar_' + key + '_title', _data), [
                            $translate('booking.calendar_' + key + '_description', _data)
                        ]
                    );
                });
            };

            if (event.type == 'accommodation') {
                let targetAccommodation = $ctrl.order.accommodations.filter(
                    item => item.eventId == event.id
                )[0];

                let activitiesDates = DateService._dateArrayParse(
                    $ctrl.getActivitiesDates()
                );

                let activitiesNotInRange = activitiesDates.filter(activityDate => {
                    let events = $ctrl.calendarGetEvents(event.type);

                    for (let i = 0; i < events.length; i++) {
                        if (activityDate.isBetween(
                                events[i].start.clone().subtract(1, 'days'),
                                events[i].end.clone().subtract(1, 'days'))) {
                            return false;
                        }
                    }

                    return true;
                });

                if (activitiesNotInRange.length > 0) {
                    return cantDoThat('accommodation_activities_out_of_ranges', event);
                }

                let targetStart = event.start;
                let targetStartFormat = DateService._dateFormat(targetStart);
                let targetEnd = event.end.clone().subtract(1, 'days');
                let targetEndFormat = DateService._dateFormat(targetEnd);

                BookingsService.validate('accommodations', {
                    accommodations: [Object.assign({
                        accommodations: $ctrl.order.accommodations.filter(
                            item => item.eventId != event.id
                        ).map(accommodation => ({
                            accommodation_id: accommodation.accommodation_id,
                            start_date: accommodation.start_date,
                            end_date: accommodation.end_date
                        })),
                        start_date: targetStartFormat,
                        end_date: targetEndFormat,
                    }, $ctrl._objectOnly(
                        targetAccommodation || {}, [
                            'accommodation_id', 'adults', 'teens', 'kids', 'options'
                        ]
                    ))]
                }).then(res => {
                    if (!res.data.success) {
                        return cantDoThat('accommodation_date_not_available', event);
                    }

                    targetAccommodation.start_date = targetStartFormat;
                    targetAccommodation.end_date = targetEndFormat;
                }, () => {
                    return cantDoThat('accommodation_date_not_available', event);
                });
            } else if (event.type == 'activity') {
                ActivitiesService.index({
                    dates: $ctrl.getAccommodationsDates()
                }).then((res) => {
                    let activity = $ctrl.activities.filter(
                        activity => activity.id == event.activityId
                    )[0];
                    let targetDateFormat = DateService._dateFormat(event.start);
                    let activities = res.data.data.filter(
                        data => data.id == event.activityId
                    );

                    if (activities.length == 1) {
                        if (activities[0].datesAvailable.indexOf(
                                targetDateFormat
                            ) != -1) {
                            return activity.order.date = targetDateFormat
                        }
                    }

                    return cantDoThat('activity_date_not_available', event);
                }, () => {
                    return cantDoThat('accommodation_date_not_available', event);
                });
            }
        };

        $calendar = $element.find('#calendar');
        $calendar.fullCalendar({
            header: {
                left: 'prev, title, next',
                center: 'title',
                right: ''
            },
            firstDay: 1,
            defaultDate: moment().startOf('month').format('YYYY-MM-DD'),
            navLinks: false,
            editable: true,
            eventStartEditable: true,
            eventLimit: true,
            eventDrop: eventHandler,
            eventResize: eventHandler,
            events: []
        });
    }

    $ctrl.calendarNextEventColor = () => {
        if ($ctrl.eventColorsIndex < $ctrl.eventColors.length - 1) {
            $ctrl.eventColorsIndex++;
        } else {
            $ctrl.eventColorsIndex = 0;
        }

        return $ctrl.eventColors[$ctrl.eventColorsIndex];
    }

    $ctrl.calendarAddEvent = (event) => {
        $calendar.fullCalendar('addEventSource', [event]);
    };

    $ctrl.calendarAddPlaceholder = (start_date, end_date) => {
        $ctrl.calendarDeletePlaceholder();

        $calendar.fullCalendar('addEventSource', [{
            color: '#00ca95',
            editable: false,
            startEditable: false,
            _id: 'placeholder',
            type: 'placeholder',
            start: start_date.format('YYYY-MM-DD'),
            end: end_date.add(1, 'days').format('YYYY-MM-DD'),
        }]);
    };

    $ctrl.goToAccommodationStep = () => {
        $ctrl.order.accommodations = [];
        $ctrl.goState('add_accommodation');
    }

    $ctrl.calendarDeletePlaceholder = () => {
        $calendar.fullCalendar('removeEvents', ['placeholder']);
    };

    $ctrl.calendarDeleteActivities = () => {
        $ctrl.calendarDeleteEvents(event => event.type == 'activity');
    };

    $ctrl.calendarDeleteEvents = (callback) => {
        $calendar.fullCalendar('removeEvents', callback);
    };

    $ctrl.$onInit = () => {
        $ctrl.calendarInit();

        CountryService.list().then((res) => {
            $ctrl.countries = res.data.data;
        });

        $ctrl.goState('add_accommodation');
    };
};

module.exports = {
    bindings: {
        locale: '@',
        params: '<',
        onChange: '&',
    },
    controller: [
        '$q',
        '$http',
        '$filter',
        '$timeout',
        '$element',
        'UrlService',
        'DateService',
        'BookingsService',
        'GiftCardsService',
        'ActivitiesService',
        'InterestsService',
        'AccommodationsService',
        'InvoicesService',
        'CountryService',
        'AccommodationTypesService',
        'FormBuilderService',
        'ModalService',
        BookingComponent
    ],
    templateUrl: '/assets/tpl/pages/booking.html'
};