let InterestsService = function(ApiRequest) {
    let uriPrefix = '/interests';

    return new (function() {
        this.index = function() {
            return ApiRequest.get(uriPrefix);
        };
    });
};

module.exports = [
    'ApiRequest',
    InterestsService
];