let ModalAccommodationPopupComponent = function(
    FormBuilderService
) {
    let $ctrl = this;

    $ctrl.submit = () => {
        $ctrl.modal.scope.submit();
        $ctrl.close();
    };
    
    $ctrl.$onInit = () => {
        $ctrl.cart = $ctrl.modal.scope.cart;
    };

    $ctrl.$onDestroy = function() {};
};

module.exports = {
    bindings: {
        close: '=',
        modal: '='
    },
    controller: [
        'FormBuilderService',
        ModalAccommodationPopupComponent
    ],
    templateUrl: () => {
        return '/assets/tpl/modals/modal-accommodation.html';
    }
};