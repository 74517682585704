let OfferService = function(ApiRequest) {
    let uriPrefix = '/offers';

    return new (function() {
        this.index = function(data) {
            return ApiRequest.get(
                uriPrefix, data
            );
        };
        
        this.indexPost = function(data) {
            return ApiRequest.post(
                uriPrefix + '/index', data
            );
        };

        this.checkout = function(data) {
            return ApiRequest.post(
                uriPrefix + '/checkout', data
            );
        };

        this.calcRequestPrice = function(data) {
            return ApiRequest.post(uriPrefix + '/requests/calc-price', data);
        };

        this.makeRequestValidate = function(data) {
            return ApiRequest.post(uriPrefix + '/requests/validate', data);
        };

        this.makeRequest = function(data) {
            return ApiRequest.post(uriPrefix + '/requests', data);
        };
    });
};

module.exports = [
    'ApiRequest',
    OfferService
];