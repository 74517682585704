let ModalCartQuantityComponent = function(
    $q,
    $element,
    $timeout,
    OfferService,
    FormBuilderService
) {
    let $ctrl = this;
    let accommodations = [];

    let nightsMin = 1;
    let nightsMax = 14;

    $ctrl.totalPrice = 0;
    $ctrl.nightsOptions = [
        ...Array(nightsMax - (nightsMin - 1)).keys()
    ].map(item => item + nightsMin)

    $ctrl.updateNanoScroller = () => {
        $timeout(() => {
            $($element.find('.nano')).nanoScroller();
        }, 100);
    };

    $ctrl.selectAccommodationType = (accommodationType) => {
        $ctrl.selectAccommodation(false);
        $ctrl.selectAccommodationOption(false);

        $ctrl.form.values.accommodation_type_id = accommodationType.id;
        $ctrl.accommodations = accommodations.filter(
            accommodation => accommodation.accommodation_type_id == accommodationType.id
        );

        $ctrl.updateNanoScroller();
        $ctrl.updatePrice();
    };

    $ctrl.buildForm = () => {
        $ctrl.form = FormBuilderService.build({
            accommodation_type_id: null
        }, (form) => {
            console.log(form, form);
        });

        $ctrl.selectAccommodationType($ctrl.accommodationTypes[0]);
    }

    $ctrl.selectAccommodation = (accommodation) => {
        $ctrl.selectedAccommodation = accommodation ? accommodation : null;
        $ctrl.form.values.accommodation_id = accommodation ? accommodation.id : null;
        $ctrl.form.values.accommodation_option_id = null;
        $ctrl.form.values.people = accommodation ? accommodation.people_min : null;
        $ctrl.form.values.nights = nightsMin;
        $ctrl.updatePrice();
    };

    $ctrl.selectAccommodationOption = (accommodation_option_id) => {
        let option = $ctrl.selectedAccommodation ? ($ctrl.selectedAccommodation.options.filter(
            option => option.id == accommodation_option_id
        )[0] || null) : null;

        $ctrl.selectedAccommodationOption = option ? option : null;
        $ctrl.form.values.accommodation_option_id = option ? option.id : null;
        $ctrl.updatePrice();
    };

    $ctrl.updatePrice = () => {
        if (!$ctrl.selectedAccommodation) {
            return $ctrl.totalPrice = 0;
        }

        OfferService.calcRequestPrice({
            accommodation_id: $ctrl.selectedAccommodation.id,
            accommodation_option_id: $ctrl.selectedAccommodation ? ($ctrl.selectedAccommodation.options.filter(
                option => option.id == $ctrl.form.values.accommodation_option_id
            )[0] ? $ctrl.form.values.accommodation_option_id : null) : null,
            nights: $ctrl.form.values.nights,
            people: $ctrl.form.values.people,
        }).then(res => {
            $ctrl.totalPrice = res.data.price;
        });
    };

    $ctrl.makeReservation = () => {
        OfferService.makeRequest({
            accommodation_id: $ctrl.selectedAccommodation.id,
            accommodation_option_id: $ctrl.selectedAccommodationOption ?
                $ctrl.selectedAccommodationOption.id : null,
            nights: $ctrl.form.values.nights,
            people: $ctrl.form.values.people,
        }).then(res => {
            $ctrl.close();
            $ctrl.modal.scope.submit(res.data.data);
        });
    };

    $ctrl.$onInit = () => {
        accommodations = $ctrl.modal.scope.accommodations;
        $ctrl.accommodationTypes = $ctrl.modal.scope.accommodationTypes;
        
        $ctrl.buildForm();
        $ctrl.updateNanoScroller();
    };

    $ctrl.$onDestroy = function() {};
};

module.exports = {
    bindings: {
        close: '=',
        modal: '='
    },
    controller: [
        '$q',
        '$element',
        '$timeout',
        'OfferService',
        'FormBuilderService',
        ModalCartQuantityComponent
    ],
    templateUrl: () => {
        return '/assets/tpl/modals/offers/modal-cart-gift-card-editor.html';
    }
};