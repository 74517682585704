let GiftCardComponent = function(
    GiftCardsService
) {
    let $ctrl = this;

    $ctrl.giftCard = false;
    $ctrl.hasItems = false;

    $ctrl.$onInit = () => {
        GiftCardsService.read($ctrl.giftCardUid).then((res) => {
            $ctrl.giftCard = res.data.data;
            $ctrl.hasItems = 
                $ctrl.giftCard.accommodations_amount > 0 || 
                $ctrl.giftCard.activities_amount > 0 ||
                $ctrl.giftCard.accommodations.length || 
                $ctrl.giftCard.activities.length;

            $ctrl.accommodationsWithOptions = $ctrl.giftCard.accommodations ? 
                $ctrl.giftCard.accommodations.filter(
                    giftCardAccommodation => giftCardAccommodation.accommodation.options.length
                ) : [];
        }, res => {
            $ctrl.giftCard = null;
        });
    }

    let popupWindow = (url, title, win, w, h) => {
        const y = win.top.outerHeight / 2 + win.top.screenY - ( h / 2);
        const x = win.top.outerWidth / 2 + win.top.screenX - ( w / 2);

        return win.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+y+', left='+x);
    }

    $ctrl.print = () => {
        var divContents = $("#exportable").html();
        var printWindow = popupWindow('', 'Print PDF', window, 800, 800);
        
        jQuery.get('/assets/css/style.min.css', function (data) {
            printWindow.document.write('<html><head><style>');
            printWindow.document.write(data);
            printWindow.document.write('</style></head><body>');
            printWindow.document.write(divContents);
            printWindow.document.write('</body></html>');
            $(printWindow.document).find('.block-giftcard-layout').addClass('printable');
            printWindow.print();
        });
    }
};

module.exports = {
    bindings: {
        giftCardUid: '@',
    },
    controller: [
        'GiftCardsService',
        GiftCardComponent
    ],
    templateUrl: '/assets/tpl/pages/gift-card-component.html'
};